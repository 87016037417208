import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import {addDays} from 'date-fns';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const today = moment(value).isSame(Date.now(), 'day');
    const tomorrow = moment(value).isSame(addDays(Date.now(), 1), 'day');
    if (today) {
      return 'Bugün';
    } else if (tomorrow) {
      return 'Yarın';
    }
    return moment(value).locale('tr').format('D MMMM');
  }

}
