import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserStore} from '@core/store/user.store';
import {Merchant} from '@core/models/merchant.model';
import { AngularFirestore } from '@angular/fire/firestore';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpClient,
    private store: UserStore,
    private fireStore: AngularFirestore
  ) {}

  public async getBankAccount() {
    const uid = this.store.getValue().user.uid;
    const user = await this.fireStore.collection('users').doc(uid).ref.get();

    return user.get('bank_account');
  }

  public updateBankAccount(bankAccount: any) {
    const uid = this.store.getValue().user.uid;
    return this.fireStore.collection('users').doc(uid).update({
      bank_account: bankAccount
    });
  }

  public getMerchantDetails() {
    const uid = this.store.getValue().user.uid;

    return this.http.get(environment.cloudFunctionUrl + 'app/payment/merchant/' + uid)
      .toPromise();
  }

  public createMerchant(merchant: Merchant) {
    const {
      address, contactName, contactSurname,
      email, iban
    } = merchant;

    const subMerchantExternalId = this.store.getValue().user.uid;

    return this.http.post(environment.cloudFunctionUrl + 'app/payment/merchant-register', {
      subMerchantExternalId,
      address, contactName,
      contactSurname, email,
      iban
    }).toPromise();
  }

  public updateMerchant(merchant: Merchant) {
    const uid = this.store.getValue().user.uid;
    const {
      address,
      contactName,
      contactSurname,
      email,
      iban,
      subMerchantKey,
    } = merchant;

    const requestData: Merchant = {
      subMerchantKey,
      address,
      contactName,
      contactSurname,
      email,
      iban,
      uid
    };
    return this.http.post(environment.cloudFunctionUrl + 'app/payment/merchant-update', requestData).toPromise();
  }
}
