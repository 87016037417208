import {Thread} from './../../../../../core/models/thread.model';
import {Message} from './../../../../../core/models/message.model';
import {Component, OnInit, Input} from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {

  @Input() mine: boolean;
  @Input() message: Message;
  @Input() isDoctor: boolean;
  @Input() thread: Thread;

  constructor() {
  }

  ngOnInit() {
  }

  showImg(url: string) {
    Swal.fire({
      html: '<img style="width: 100%" src=' + url + '>',
      width: '30%',
      showConfirmButton: false
    });
  }

}
