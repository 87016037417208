import { Message } from './message.model';

export class Thread {
    messages: Message[];
    create_ip: string;
    create_uid: string;
    doctor: Doctor;
    last_message: {
        date: number;
        text: string;
    };
    new_message: {
        count_doctor: 0;
        count_patient: 0;
    };
    patient: Patient;
    status: 0 | 1 | 2;
    id?: string;
    call?: Call;
}

class Doctor {
    firstname: string;
    lastname: string;
    profile_image: string;
    uid: string;
}

class Patient {
    display_name: string;
    profile_image: string;
    status: number;
    uid: string;
}

class Call {
  request_author_uid: string;
  status: {
    doctor: {
      audio: boolean;
      mic: boolean;
    };
    patient: {
      audio: boolean;
      mic: boolean;
    }
  };
  video: number;
  voice: number;
}
