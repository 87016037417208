import {Component, OnInit} from '@angular/core';
import {UserStore} from '@core/store/user.store';
import {Router} from '@angular/router';
import {AuthNoticeService, AuthService} from '@core/auth';

@Component({
  selector: 'kt-select-role',
  templateUrl: './select-role.component.html',
  styleUrls: ['../auth.component.scss']
})
export class SelectRoleComponent implements OnInit {


  constructor(
    private store: UserStore,
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService
  ) {
  }

  async ngOnInit() {
    await this.auth.logout();
    this.auth.unsubscribeUser();

    this.authNoticeService.setDescription(
      `Online diyet için aradığın her şey Askipo'da.`
    );
  }

  select(role: string) {
    this.store.update(() => ({
      data: role,
    }));
    this.router.navigate(['/auth/login']);
  }

}
