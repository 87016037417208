// Angular
import { Injectable } from '@angular/core';
// Angular in memory
import { InMemoryDbService } from 'angular-in-memory-web-api';
// RxJS
import { Observable } from 'rxjs';

@Injectable()
export class FakeApiService implements InMemoryDbService {


  constructor() { }

  createDb(): {} | Observable<{}> {
    const db = {
    };
    return db;
  }
}
