import { User } from '@core/auth/_models/user.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kt-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {

  @Input() user: User;

  constructor() { }

  ngOnInit() {
  }

}
