import { FormsModule } from '@angular/forms';
import { PartialsModule } from '@partials/partials.module';
import { ChatComponent } from './chat.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    ChatComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    PartialsModule,
    DragDropModule,
    InfiniteScrollModule,
    MatProgressBarModule,
    RouterModule.forChild([
      {
        path: '',
        component: ChatComponent
      },
    ]),
    SweetAlert2Module,
    NgbRatingModule,
  ]
})
export class ChatModule { }
