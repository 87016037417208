import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'subtractDates' })
export class SubtractDatesPipe implements PipeTransform {
  transform(value: number): number {
    const milliseconds = value;
    const seconds = milliseconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    if (days < 0 ) {return 0; }
    return Math.floor(days);
  }
}
