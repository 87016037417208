import { Injectable } from '@angular/core';
import { UserState, UserStore } from './../store/user.store';
import { Query } from '@datorama/akita';

@Injectable({
  providedIn: 'root'
})
export class UserQuery extends Query<UserState> {

  user$ = this.select(state => state.user);
  name$ = this.select(state =>
    'null');
  is_doctor$ = this.select(state => null);

  appointment$ = this.select(state => state.appointment);

  hasPackage$ = this.select(state => !!state.package);

  package$ = this.select(state => state.package);

  data$ = this.select(state => state.data ? state.data : 'patient');
  constructor(protected store: UserStore) {
    super(store);
  }
}
