import { MatDialog } from '@angular/material';
import {UserStore} from '@core/store/user.store';
import {PatientService} from '@core/services/patient.service';
import {Subscription} from 'rxjs';
import {Appointment} from '@core/models/appointment.model';
import {DoctorService} from '@core/services/doctor.service';
import {Component, OnInit, ChangeDetectorRef, PipeTransform} from '@angular/core';
import {UserService} from '@core/services/user.service';
import {first, map, startWith} from 'rxjs/operators';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import { UserPackage } from '@core/models/user_packages.model';
import { ClientAssignModalComponent } from '@pages/content/doctor/client-list/client-assign-modal/client-assign-modal.component';
import {User} from '@core/auth/_models/user.model';
import Swal from 'sweetalert2';
@Component({
  selector: 'kt-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss'],
  providers: [DecimalPipe]
})

export class MeetingsComponent implements OnInit {
  subscribers: any;

  meetings: any;

  appointments: any;

  subscription: Subscription;

  status = 0;

  table = {
    page: 1,
    pageSize: 20,
    collectionSize: 1
  };

  controls = new FormGroup({
    filter: new FormControl(''),
    size: new FormControl(20),
    page: new FormControl(1),
  });

  isDoctor: boolean;

  users: User[] = [];

  constructor(
    private store: UserStore,
    private cd: ChangeDetectorRef,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private userService: UserService,
    private router: Router,
    private pipe: DecimalPipe,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.isDoctor = this.store.getValue().user.is_doctor;

    if (this.isDoctor) {
      this.subscription = this.doctorService
        .getAppointments()
        .subscribe(async (appointments: any[]) => {

          const _appointments = appointments.filter(appt => appt.assigned_uid !== 'none').map(async (appt) => {
            const availableUser = this.users.find(_user => _user.uid === appt.assigned_uid);
            if (availableUser) {
              return {
                ...appt,
                 user: availableUser,
              };
            }
            const userRef = await this.userService.getUserPromise(appt.assigned_uid);
            const user = {
              ...userRef.data(),
              uid: userRef.id
            };
            this.users.push();
            return {
              ...appt,
              user
            };
          });

          const __appointments = await Promise.all(_appointments);
          this.appointments = this.controls.valueChanges.pipe(startWith(''), map(val => {
            this.table.collectionSize = __appointments.length;
            return this.search(val, __appointments, this.pipe);
          }));
          this.table.collectionSize = __appointments.length;
          // const __subscribers = await Promise.all(_subscribers);
          // this.subscribers = this.filter.valueChanges.pipe(startWith(''),

          //   map(text => search(text, __subscribers, this.pipe)));

          this.cd.markForCheck();
        });
      return;
    }

    this.patientService.getAppointments().subscribe(async appointments => {
      const _appointments = appointments.map(async (appt) => {
        const availableUser = this.users.find(_user => _user.uid === appt.author_uid);
        if (availableUser) {
          return {
            ...appt,
            user: availableUser,
          };
        }
        const userRef = await this.userService.getUserPromise(appt.author_uid);
        const user = {
          ...userRef.data(),
          uid: userRef.id
        };
        this.users.push();
        return {
          ...appt,
          user
        };
      });

      const __appointments = await Promise.all(_appointments);
      this.appointments = this.controls.valueChanges.pipe(startWith(''), map(val => {
        this.table.collectionSize = __appointments.length;
        return this.search(val, __appointments, this.pipe);
      }));
      this.table.collectionSize = __appointments.length;
      // const __subscribers = await Promise.all(_subscribers);
      // this.subscribers = this.filter.valueChanges.pipe(startWith(''),

      //   map(text => search(text, __subscribers, this.pipe)));

      this.cd.markForCheck();
    });

  }

  showProfile(id: string) {
    if (this.isDoctor) {
      this.router.navigate(['client-profile/' + id]);
      return;
    }
  }

  search(text: any, value: any[], pipe: PipeTransform) {
    return value.filter(val => {
      const term = this.controls.value.filter.toLowerCase();
      return (val.user.is_doctor ? val.user.firstname + ' ' + val.user.lastname : val.user.display_name)
        .toLowerCase().includes(term);
    }).slice(
      (this.controls.value.page - 1) * this.controls.value.size,
      (this.controls.value.page - 1) * this.controls.value.size + this.controls.value.size
    );
  }

  doctorDetail(id: string) {
    this.router.navigate(['doctor-details/' + id]);
  }
  navigateMenu(uid: string) {
    this.router.navigate(['client-profile', uid, '1']);
  }

  appointmentDetail(appointment: Appointment) {
    this.store.update(() => ({
      appointment
    }));
    console.log('updated', appointment);
    this.router.navigate(['appointment-detail']);
  }

  async menuAssign(appoinment: Appointment) {
    const client = await this.doctorService.getUserPackage(appoinment.assigned_uid);
    if (client.is_deleted === true) {
      Swal.fire({
        title: `Uyarı!`,
        text: 'Bu kullanıcı hesabını sildiği için menü atayamazsın.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Tamam',
      });
      return;
    }
    this.dialog.open(ClientAssignModalComponent, {
      width: '700px',
      data: { client },
    });
  }
}
