// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject} from 'rxjs';
import {finalize, first, takeUntil, tap} from 'rxjs/operators';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Store
import {Store} from '@ngrx/store';
// Auth
import {AuthNoticeService, AuthService} from '../../../../core/auth';
import {UserQuery} from '@core/query/user.query';
import { UserStore } from '@core/store/user.store';

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  // Public params
  loginForm: FormGroup;
  loading = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];

  currentTab = 'login'; // 'login' | 'otp'

  error: string;

  private unsubscribe: Subject<any>;

  private returnUrl: any;

  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private query: UserQuery,
    private store: UserStore,
  ) {
    this.unsubscribe = new Subject();
  }

  async ngOnInit() {
    this.initLoginForm();
    this.auth.logout();
    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl || '/';
    });
    const role = this.store.getValue().data;
    this.authNoticeService.setDescription(
      role === 'patient'
        ? `Askipo'yla dilediğin yerden diyetisyenine ulaşabilir ve menünü yönetebilirsin.`
        : `Askipo'yla dilediğin yerden danışanlarınla online görüşme yapabilir ve menülerini yönetebilirsin.`
    );
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6)
        ])
      ]
    });
  }

  async submit() {
    this.error = '';
    const controls = this.loginForm.controls;

    /** check form */
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    this.loading = true;
    const isPartner = await this.auth.isPartner(controls.email.value.trim());
    if (!isPartner) {
      this.error = 'Girdiğin e-postaya ait kullanıcı bulunamadı.';
      this.loading = false;
      this.cdr.markForCheck();
      return;
    }

    await this.auth.signInWithEmailAndPassword(controls.email.value.trim(), controls.password.value.trim()).then((res) => {
      console.log('result:', res);
      if (res.user) {
        this.router.navigate(['/order-list']);
      }
    }).catch((err) => {
      console.log(err);
      switch (err.code) {
        case 'auth/wrong-password':
          this.error = 'Yanlış bir şifre girdin.';
          break;
        case 'auth/user-not-found':
          this.error = 'Girdiğin e-postaya ait kullanıcı bulunamadı.';
          break;
        case 'auth/too-many-requests':
          this.error = 'Çok fazla giriş yapma isteğinde bulundun. Lütfen daha sonra tekrar dene.';
          break;
        default:
          this.error = 'Bilinmeyen bir hata oluştu.';
          break;
      }
    }).finally(() => {
      this.loading = false;
      this.cdr.markForCheck();
    });
  }

  isControlHasError(controlName: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.invalid && (control.touched);
    return result;
  }
}
