export class User {
    about: string;
    address: string;
    approved_email: boolean;
    city: string;
    appointment: {
        minutes: number;
        price: number;
        status: {
            chat: boolean;
            video: boolean;
            voice: boolean;
        }
    };
    birth_date: number;
    client: {
        last_login_ip: string;
        register_ip: string;
    };
    email: string;
    enabled: boolean;
    firstname: string;
    is_doctor: boolean;
    is_online: boolean;
    lastname: string;
    display_name: string;
    sub_merchant_key: string;
    phone_number: string;
    profile_image: string;
    video: string;
    questions: string[];
    pre_meeting: boolean;
    register_date: number;
    review: {
        average_point: number;
        total_evaluation: number;
        total_point: number;
    };
    role: 'doctor' | 'user';
    bank_account?: {
      iban: string;
    };
    documents?: {
      school_certificate: string;
      bank_certificate: string;
      identification: string;
    };
    school?: string;
    uid?: string;
}
