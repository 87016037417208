import {Review} from './../models/review.model';
import {HttpClient} from '@angular/common/http';
import {UserStore} from './../store/user.store';
import {User} from './../auth/_models/user.model';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {Injectable} from '@angular/core';
import {Appointment} from '../models/appointment.model';
import {environment} from '../../../environments/environment';
import {Package} from '@core/models/package.model';
import firebase from 'firebase/app';
import {first} from 'rxjs/operators';
import {UserPackage} from '@core/models/user_packages.model';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  constructor(
    private fireStore: AngularFirestore,
    private http: HttpClient,
    private userStore: UserStore
  ) {
  }

  public getUID() {
    return this.userStore.getValue().user.uid;
  }

  public patients() {
    return this.fireStore.collection('users', query => query.where(
      'is_doctor', '==', false
    )).valueChanges({idField: 'uid'}) as Observable<User[]>;
  }

  public reviews() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore.collection('reviews', query => query.where(
      'doctor.uid', '==', uid
    )).valueChanges({idField: 'id'}) as Observable<Review[]>;
  }

  public answerComment(reviewID: string, answer: string) {
    return this.fireStore
      .collection('reviews')
      .doc(reviewID)
      .update({
        answer
      });
  }

  public async updatePackageInfo(firstname: string, lastname: string, profile_image: string) {
    const uid = this.userStore.getValue().user.uid;
    const packRef = await this.fireStore.collection('packages').ref.where('author.uid', '==', uid).get();
    packRef.docs[0].ref.update({
      ['author.firstname']: firstname,
      ['author.lastname']: lastname,
      ['author.profile_image']: profile_image
    });
  }

  public getAppointments() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore
      .collection('appointments',
        query => query.where('author_uid', '==', uid).orderBy('date', 'desc')
      )
      .valueChanges({idField: 'id'}) as Observable<Appointment[]>;
  }

  public getFullAppointments() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore
      .collection('appointments',
        query => query.where('author_uid', '==', uid))
      .valueChanges({idField: 'id'}) as Observable<Appointment[]>;
  }

  public getEvents() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore
      .collection('events',
        query => query.where('uid', '==', uid))
      // TODO event modeli oluşturulacak
      .valueChanges({idField: 'id'}) as Observable<any[]>;
  }

  public getPackages() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore
      .collection('packages', query => query.where('author.uid', '==', uid))
      .valueChanges({idField: 'id'}) as Observable<Package[]>;
  }

  public getLatestAppointment(patientUID: string) {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore.collection('appointments').ref
      .where('assigned_uid', '==', patientUID)
      .where('author_uid', '==', uid)
      .orderBy('date', 'asc').limit(1).get();
  }

  public getNextAppointment(patientUID: string) {
    const uid = this.userStore.getValue().user.uid;
    const now = firebase.firestore.Timestamp.now().toMillis();
    return this.fireStore.collection('appointments').ref
      .where('assigned_uid', '==', patientUID)
      .where('author_uid', '==', uid)
      .where('date', '>', now)
      .orderBy('date', 'asc').limit(1).get();
  }

  public getSubscribers() {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore.collection('user_packages', query => query.where('author_uid', '==', uid))
      .valueChanges({idField: 'id'}) as Observable<UserPackage[]>;
  }

  public async getApprovedOrders() {
    const myPackage = await this.getPackages().pipe(first()).toPromise();
    if (myPackage[0]) {
      return this.fireStore.collection('orders', query =>
        query
          .where('product_id', '==', myPackage[0].id)
          .where('status', '==', 'approved'))
        .valueChanges();
    }
    return 'no-orders';
  }

  public async updatePackages(id: string, data: any): Promise<any> {
    delete data.id;
    const {firstname, lastname, profile_image, uid, pre_meeting, review} = this.userStore.getValue().user;
    const average_point = review.average_point;

    const appointments = await this.fireStore.collection('appointments')
      .ref.where('author_uid', '==', uid)
      .orderBy('date', 'asc').get();

    const nearest_appointment_date = appointments.docs.length > 0 ? appointments.docs[0].data().date : 100000000000000000;

    if (!id) {
      return this.fireStore.collection('packages').add({
        ...data,
        author: {
          firstname, lastname, average_point,
          profile_image, uid, pre_meeting, nearest_appointment_date
        }
      });
    }

    return this.fireStore.collection('packages').doc(id).update({
      ...data,
      author: {
        firstname, lastname, average_point,
        profile_image, uid, pre_meeting, nearest_appointment_date
      }
    });
  }

  public async _updatePackage(data: any) {
    const uid = this.userStore.getValue().user.uid;

    const docRef = await this.fireStore.collection('packages').ref.where('author.uid', '==', uid).get();
    return docRef.docs[0].ref.update({
      author: {
        ...docRef.docs[0].data().author,
        pre_meeting: data.pre_meeting
      }
    });
  }

  public async createAppointment(data) {
    const doctor = this.userStore.getValue().user;
    const appointment: Appointment = {
      assigned_uid: 'none',
      author_uid: doctor.uid,
      date: data.date,
      create_ip: (await this.ipAddress) || '',
      minutes: 30,
      note: 'none',
      thread_id: 'none',
      was_review: false,
      doctor_note: '',
      completed: false
    };
    return this.fireStore.collection('appointments').add(appointment);
  }

  public async createEvent(data) {
    const uid = this.userStore.getValue().user.uid;
    return this.fireStore.collection('events')
      .add({
        uid, ...data, create_ip: (await this.ipAddress) || '',
      });
  }

  public async updateAppointment(id: string, data) {
    return this.fireStore.collection('appointments').doc(id)
      .update({
        ...data,
        modify_ip: (await this.ipAddress) || ''
      });
  }

  public async updateEvent(id: string, data) {
    this.fireStore
      .collection('events')
      .doc(id)
      .update({
        ...data,
        modify_ip: (await this.ipAddress) || ''
      });
  }

  public deleteAppointment(id: string) {
    return this.fireStore
      .collection('appointments')
      .doc(id)
      .delete();
  }

  public deleteEvent(id: string) {
    return this.fireStore
      .collection('events')
      .doc(id)
      .delete();
  }

  public getUserPackage(clientID: string) {
    return this.fireStore
      .collection('user_packages')
      .ref.where('assigned_user.uid', '==', clientID)
      .where('author_uid', '==', this.getUID())
      .limit(1)
      .get()
      .then((data) => {
        return {...data.docs[0].data(), id: data.docs[0].id} as UserPackage;
      });
  }

  public get ipAddress() {
    return this.http.get(environment.ipURL).toPromise() as Promise<string>;
  }
}
