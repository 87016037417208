import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth Guard
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RoleGuard } from '@core/auth/_guards/role.guard';
// import {PackageGuard} from '@core/auth/_guards/package.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/auth/login']);

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },

  {
    path: '',
    component: BaseComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: 'order-list',
        loadChildren: () => import('./views/pages/content/doctor/list/doctor-list.module').then(m => m.DoctorListModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'sales' }
      },
      {
        path: 'partner-products',
        loadChildren: () => import('./views/pages/content/partner-products/partner-products.module').then(m => m.PartnerProductsModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'products' }
      },
      {
        path: 'member',
        loadChildren: () => import('./views/pages/content/member/member.module').then(m => m.MemberModule),
        canActivate: [RoleGuard],
        data: { type: 'read', field: 'member' }
      },
      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
        },
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'order-list', pathMatch: 'full' },
      { path: '**', redirectTo: 'order-list', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
