import {HttpClient} from '@angular/common/http';
import {UserStore} from './../store/user.store';
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable, combineLatest, of, BehaviorSubject} from 'rxjs';
import {Thread} from '../models/thread.model';
import {switchMap} from 'rxjs/internal/operators/switchMap';
import {Message} from '../models/message.model';
import {finalize, first, tap, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import firebase from 'firebase/app';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {Appointment} from '../models/appointment.model';
import {User} from '@core/auth/_models/user.model';
import App = firebase.app.App;
import {Review} from '@core/models/review.model';

@Injectable({
  providedIn: 'root'
})
export class ThreadService {

  private statusFilter$: BehaviorSubject<number>;
  private activeThread: BehaviorSubject<Thread> = new BehaviorSubject<Thread>(null);

  constructor(
    private fireStore: AngularFirestore,
    private fireStorage: AngularFireStorage,
    private store: UserStore,
    private http: HttpClient
  ) {
    this.statusFilter$ = new BehaviorSubject(1);
  }

  public setStatusFilter(value: number) {
    this.statusFilter$.next(value);
  }

  public getStatusFilter(): number {
    return this.statusFilter$.getValue();
  }

  public doctorDetail(doctorUID: string) {
    return this.fireStore.collection('users').doc(doctorUID).valueChanges();
  }

  public setActiveThread(thread: Thread) {
    this.activeThread.next(thread);
  }

  public getActiveThread() {
    return this.activeThread;
  }

  public callingVoice() {
    const {uid, is_doctor} = this.store.getValue().user;
    const now = firebase.firestore.Timestamp.now().toMillis();
    return this.fireStore.collection('threads', query => {
      const field = is_doctor ? 'doctor' : 'patient';
      return query.where(field + '.uid', '==', uid).where('call.voice', '==', 1);
    }).valueChanges({idField: 'id'}) as Observable<Thread[]>;
  }

  public completeAppointment(id: string) {
    return this.fireStore.collection('appointments').doc(id).update({completed: true});
  }

  public callingVideo() {
    const {uid, is_doctor} = this.store.getValue().user;
    const now = firebase.firestore.Timestamp.now().toMillis();
    return this.fireStore.collection('threads', query => {
      const field = is_doctor ? 'doctor' : 'patient';
      return query.where(field + '.uid', '==', uid).where('call.video', '==', 1);
    }).valueChanges({idField: 'id'}) as Observable<Thread[]>;
  }

  public async callChange(threadID: string, type: string, value: number) {
    return this.fireStore.collection('threads').doc(threadID)
      .update({[`call.${type}`]: value});
  }

  public async callStatusChange(threadID: string, key: string, value: boolean) {
    return this.fireStore.collection('threads').doc(threadID)
      .update({[`call.status.patient.${key}`]: value});
  }

  public getThreadsByIds(doctor: string, patient: string) {
    return this.fireStore.collection('threads').ref
      .where('doctor.uid', '==', doctor)
      .where('patient.uid', '==', patient).get();
  }

  public reviewDoctor(doctor: User, comment: string, point: number) {
    const {display_name, profile_image, uid} = this.store.getValue().user;
    const date = firebase.firestore.Timestamp.now().toMillis();

    return this.fireStore.collection('reviews').add({
      author: {
        display_name,
        profile_image,
        uid,
      },
      comment,
      point,
      date,
      doctor,
      answer: '',
      status: false,
    });
  }

  public editReview(reviewID: string, review: Review) {
    return this.fireStore.collection('reviews').doc(reviewID).update({
      status: false,
      comment: review.comment,
      point: review.point
    });
  }

  public async hasReviewed(doctorUID: string) {
    const {uid} = this.store.getValue().user;
    const ref = await this.fireStore.collection('reviews').ref
      .where('doctor.uid', '==', doctorUID)
      .where('author.uid', '==', uid).get();

    return ref.docs.length > 0;
  }

  public thread(id: string): Observable<Thread> {
    return this.fireStore
      .collection('threads')
      .doc(id)
      .valueChanges() as Observable<Thread>;
  }

  public appointment(id: string): Observable<Appointment> {
    return this.fireStore
      .collection('appointments')
      .doc(id)
      .valueChanges() as Observable<Appointment>;
  }

  public appointmentPromise(id: string) {
    return this.fireStore
      .collection('appointments')
      .doc(id).ref.get();
  }

  public cancelAppointment(id: string) {
    return this.fireStore
      .collection('appointments')
      .doc(id).update({
        assigned_uid: 'none',
        thread_id: 'none',
        type: 'none',
      });
  }

  /** @summary Doktora ait tüm thread'leri döndürür. */
  public getAllThreads(): Observable<Thread[]> {
    const {uid, is_doctor} = this.store.getValue().user;

    // tslint:disable-next-line: deprecation
    return combineLatest(
      this.statusFilter$
    ).pipe(
      switchMap(([status]) =>
        this.fireStore.collection('threads', ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

          const fieldName = (is_doctor ? 'doctor' : 'patient') + '.uid';

          console.log(fieldName);

          query = query.orderBy('last_message.date', 'desc')
            .where(fieldName, '==', uid);
          query = query.where('status', '==', status);
          return query;
        }).valueChanges({idField: 'id'}) as Observable<Thread[]>
      )
    );
  }

  public getMutualAppointments(id: string): Observable<Appointment[]> {
    const {uid, is_doctor} = this.store.getValue().user;
    return this.fireStore.collection('appointments', ref => {
      return ref
        .where(is_doctor ? 'author_uid' : 'assigned_uid', '==', uid)
        .where(is_doctor ? 'assigned_uid' : 'author_uid', '==', id)
        .orderBy('date', 'desc');
    }).valueChanges({
      idField: 'id'
    }) as Observable<Appointment[]>;
  }

  public getAllAppointments(): Observable<Appointment[]> {
    const uid = this.store.getValue().user.uid;

    return combineLatest(
      this.statusFilter$
    ).pipe(
      switchMap(([status]) =>
        this.fireStore.collection('appointments', ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

          const field = this.store.getValue().user.is_doctor ? 'author' : 'assigned';
          query = query.orderBy('date', 'desc')
            .where(field + '_uid', '==', uid);

          // if (status === 1) {
          //   query = query.where('date', '>=', firebase.firestore.Timestamp.now().toMillis() - 2700000);
          // } else {
          //   // // 17:30 >= 17:40
          //   // // 17:15 << now
          //   // //
          //   query = query.where('date', '<', firebase.firestore.Timestamp.now().toMillis() - 2700000);
          // }

          return query;
        }).valueChanges({idField: 'id'}).pipe(map(val => {
          return val.filter((appointment: any) => appointment.assigned_uid !== 'none');
        })) as Observable<Appointment[]>
      )
    );
  }

  public async createThread(doctorUID: string) {
    const time = firebase.firestore.Timestamp.now().toMillis();
    const user = this.store.getValue().user;
    const doctor = await this.doctorDetail(doctorUID).pipe(
      first()
    ).toPromise();

    const threadSnapshot = await this.fireStore.collection('threads').add({
      create_date: time,
      create_uid: user.uid,
      create_ip: (await this.ipAddress) || '',
      doctor: {
        uid: doctorUID,
        firstname: doctor['firstname'],
        lastname: doctor['lastname'],
        profile_image: doctor['profile_image']
      },
      last_message: {
        date: time,
        text: 'Görüşme başlatıldı.'
      },
      new_message: {
        count_doctor: 0,
        count_patient: 0
      },
      patient: {
        uid: user.uid,
        display_name: user.display_name,
        profile_image: user.profile_image,
      },
      status: 1,
      call: {
        video: 0,
        voice: 0,
        request_author_uid: 'none',
        status: {
          patient: {
            mic: true,
            audio: true,
          },
          doctor: {
            mic: true,
            audio: true,
          }
        }
      },
    });

    return {
      threadID: threadSnapshot.id,
      doctorUID
    };
  }

  /** Thread ID'si verilen Thread dökümanının mesajlarını döndürür. */
  // public async getMessages(threadID: string): Promise<Message[]> {
  //   const messagesRef = await this.fireStore
  //     .collection('threads')
  //     .doc(threadID)
  //     .collection('messages').ref
  //     .orderBy('date', 'asc')
  //     .limit(20)
  //     .get();

  //   return messagesRef.docs.map((val) => {
  //     return {
  //       ...val.data() as Message,
  //       id: val.id
  //     };
  //   });
  // }

  /** Thread ID'si verilen Thread dökümanının mesajlarını döndürür.
   */
  public getMessages(threadID: string, limit: number) {
    return this.fireStore.collection('threads').doc(threadID)
      .collection('messages', q =>
        q.orderBy('date', 'asc')
          .limitToLast(limit))
      .valueChanges({idField: 'id'}) as Observable<Message[]>;
  }

  public sendCall(threadID: string, type: string) {
    const user = this.store.getValue().user;
    return this.fireStore.collection('threads').doc(threadID).update({
      [`call.${type}`]: 1,
      [`call.request_author_uid`]: user.uid
    });
  }

  public async seenUpdate(threadID: string, UID: string): Promise<void> {
    const doc = this.fireStore.collection('threads').doc(threadID);
    await doc.update({
      'new_message.count_doctor': 0,
    });
    const documents = await doc.collection('messages').ref
      .where('UID', '==', UID)
      .where('seen', '==', false).get();

    documents.docs.forEach((snapshot) => {
      snapshot.ref.update({seen: true});
    });
  }

  public async sendMessage(threadID: string, message: string, messageType: string) {
    const threadDocument = this.fireStore.collection('threads').doc(threadID);
    const date = firebase.firestore.Timestamp.now().toMillis();
    const increment = firebase.firestore.FieldValue.increment(1);

    const UID = this.store.getValue().user.uid;

    await threadDocument.update({
      'last_message.date': date,
      'last_message.text': message,
      'new_message.count_patient': increment,
    });

    if (messageType !== 'text') {
      message = '';
    }

    const messageResult = await threadDocument.collection('messages').add({
      date,
      seen: false,
      text: message,
      UID,
      files: [],
      type: messageType,
      upload_status: messageType === 'text',
      ip: (await this.ipAddress) || ''
    });

    return messageResult.id;
  }

  public async uploadFile(file: Blob, type: string, threadID: string, messageID: string) {
    const user = this.store.getValue().user;
    const fileName = this.uuidv4();

    const ref = this.fireStorage.storage.ref('uploads').child(fileName);
    const messageRef = this.fireStore
      .collection('threads')
      .doc(threadID)
      .collection('messages')
      .doc(messageID);

    const task = this.fireStorage.upload(`uploads/${fileName}`, file);
    task.catch(() => messageRef.delete());

    const sub = task.snapshotChanges().pipe(
      finalize(async () => {
        const url = await ref.getDownloadURL();
        await messageRef.update({
          upload_status: true,
          files: [url]
        });

        await this.fireStore.collection('files').add({
          author_uid: user.uid,
          url,
          create_date: firebase.firestore.Timestamp.now().toMillis(),
          create_ip: (await this.ipAddress) || '',
          type
        });

        sub.unsubscribe();
      })
    ).subscribe();

    return {
      task: task as AngularFireUploadTask,
      ref
    };

  }

  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line: no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public async prevMessages(threadID: string, lastItem: number = null) {
    const result = await this.fireStore.collection('threads').doc(threadID)
      .collection('messages').ref
      .orderBy('date', 'desc')
      .startAfter(lastItem)
      .limit(3)
      .get();

    return result.docs.map((value) => {
      return {
        id: value.id,
        ...value.data() as Message
      };
    }).reverse();
  }

  public get ipAddress() {
    return this.http.get(environment.ipURL).toPromise() as Promise<string>;
  }
}
