export class Message {
    UID: string;
    date: number;
    files: string[];
    seen: false;
    text: string;
    type: 'text' | 'image';
    upload_status: boolean;
    id?: string;
    progress?: number;
}