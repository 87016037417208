import { combineLatest, Subscription } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin } from 'rxjs';
// Environment
import { HttpUtilsService } from '../../_base/crud';
// Models
import { User } from '../_models/user.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserStore } from '../../store/user.store';
import { first, take } from 'rxjs/operators';
import { UserPackage } from '@core/models/user_packages.model';

import * as firebase from 'firebase/app';
import { PatientService } from '@core/services/patient.service';
import { MenuAsideService, MenuConfigService } from '@core/_base/layout';
import { PaymentService } from '@core/services/payment.service';
import { apiURL, environment } from "../../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private afAuth: AngularFireAuth,
    private fireStore: AngularFirestore,
    private userStore: UserStore,
    private patientService: PatientService,
    private menuAsideService: MenuAsideService,
    private paymentService: PaymentService
  ) {
  }

  private token: string;
  private uid: string;

  userSubscription: Subscription;
  packSubscription: Subscription;

  public signInAnonymously() {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInAnonymously().then(async (value) => {
        this.uid = value.user.uid;
        this.token = await value.user.getIdToken();
        resolve(true);
      }).catch(() => reject());
    });
  }

  public async signInWithEmailAndPassword(email: string, password: string) {
    const auth = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    await this.subscribeUser();
    return auth;
  }

  public get authState() {
    return this.afAuth.authState;
  }

  public async isPartner(email: string) {
    const result = await this.fireStore.collection('partners').ref
      .where('email', '==', email)
      .get();

    return !result.empty;
  }

  public emailControl(email: string) {
    return this.fireStore.collection('users').ref.where('email', '==', email).get();
  }

  public async sendPassword(UID: string, phone: string) {
    if (UID === 'none') {
      const result = await this.fireStore.collection('users')
        .ref.where('phone_number', '==', phone)
        .where('is_doctor', '==', false).get();
      UID = result.docs[0].id;
    }

    return this.http.post(apiURL + 'sendPassword', {
      UID,
      phone,
      ip: (await this.ipAddress) || '',
      tokenUID: this.uid ? this.uid : UID
    }, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token,
      })
    }).toPromise();
  }

  // Authentication/Authorization
  async login(token: string, id: string) {
    const result = await this.afAuth.auth.signInWithCustomToken(token);
    this.fireStore.collection('tokens').doc(id).delete();

    const state = !!result.user;

    if (state) {
      this.subscribeUser();
    }

    return {
      state,
      user: result.user
    };
  }

  async registerPatient(phone: string, displayName: string, email: string, birthDate: number) {
    return this.http.post(apiURL + 'createUser', {
      phone,
      displayName,
      email,
      birthDate,
      role: 'patient',
      ip: (await this.ipAddress) || ''
    }).toPromise();
  }

  async registerDoctor(phone: string, firstName: string, lastName: string) {
    return this.http.post(apiURL + 'createUser', {
      phone,
      firstName,
      lastName,
      role: 'doctor',
      ip: (await this.ipAddress) || ''
    }).toPromise();
  }

  public unsubscribeUser() {
    if (this.userSubscription != null) {
      this.userSubscription.unsubscribe();
    }
    if (this.packSubscription != null) {
      this.packSubscription.unsubscribe();
    }
    this.userStore.update(() => ({
      appointment: null,
      user: null,
      package: null,
      merchant: {
        contactName: '',
        contactSurname: '',
        iban: ''
      }
    }));
  }

  public async subscribeUser() {
    this.unsubscribeUser();
    this.afAuth.authState.pipe(take(1)).subscribe(async (auth) => {
      if (auth) {
        if (auth.isAnonymous) {
          this.logout();
        } else {
          this.fireStore.collection('partners').doc(auth.uid).valueChanges().subscribe((user) => {
            this.userStore.update(() => ({ user: { ...user as any, uid: auth.uid } }));
            this.menuAsideService.loadMenu(user as any);
          });
        }
      }
    });
  }

  logout() {
    return this.afAuth.auth.signOut();
  }

  public async getUserIdByPhone(phone: string) {
    phone = '+9' + phone
      .replace('(', '')
      .replace(')', '')
      .replace(/\s/g, '');
    const userRef = await this.fireStore.collection('users').ref
      .where('phone_number', '==', phone)
      .get();

    return userRef.docs[0].id;
  }

  public async wasRegistered(phone: string) {
    const result = await this.fireStore.collection('users')
      .ref.where('phone_number', '==', phone).get();

    return {
      empty: result.empty,
      UID: !result.empty ? result.docs[0].id : '',
      isDoctor: !result.empty ? result.docs[0].get('is_doctor') : false,
    };
  }

  public getContracts() {
    return this.fireStore.collection('general').doc('contracts').ref.get();
  }

  public get tokens() {
    return this.fireStore.collection('tokens').valueChanges({
      idField: 'id'
    });
  }

  public get ipAddress() {
    return this.http.get(environment.ipURL).toPromise() as Promise<string>;
  }
}
