import { UserPackage } from './../../../../../../core/models/user_packages.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MenusService } from '@core/services/menus.service';
import { Observable } from 'rxjs';
import { Menu } from '@core/models/menu.model';
import Swal from 'sweetalert2';
import * as firebase from 'firebase/app';
import {SwalProvider} from '@core/providers/swal.provider';
@Component({
  selector: 'kt-client-assign-modal',
  templateUrl: './client-assign-modal.component.html',
  styleUrls: ['./client-assign-modal.component.scss'],
})
export class ClientAssignModalComponent implements OnInit {
  startDate: Date;
  menus: Observable<Menu[]>;
  client: UserPackage;
  menuID: string;
  packageEndDate: Date;
  constructor(
    private menuService: MenusService,
    private dialog: MatDialogRef<ClientAssignModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.client = this.data.client;
    this.menus = this.menuService.getMenus();
    const activePackage = this.client.packages[this.client.packages.length - 1];
    const packageStartDate = new Date(activePackage.purchase_date);
    this.packageEndDate = new Date(packageStartDate.setMonth(
      packageStartDate.getMonth() + activePackage.months.length
    ));
  }

  convertDate(date: number) {
    const today = firebase.firestore.Timestamp.now().toDate().setHours(0, 0, 0, 0);
    if (today > date) {
      return new Date(today);
    }
    return new Date(date + 86400000);
  }

  assign(event: boolean) {
    console.log(event);
    if (event) {
      this.dialog.close();
      Swal.fire({
        title: 'Başarılı!',
        text: `Menü ataması tamamlandı.`,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Tamam',
      });
    } else {
      this.dialog.close();
      SwalProvider.fire('Hata', 'Bilinmeyen bir hata oluştu.', 'error');
    }
  }
}
