// Angular
import {Component, ElementRef, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
// Layout
import {LayoutConfigService, SplashScreenService, TranslationService} from '../../../core/_base/layout';
// Auth
import {AuthNoticeService} from '../../../core/auth';
import {UserQuery} from '@core/query/user.query';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  selector: 'kt-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
  // Public properties
  today: number = Date.now();
  headerLogo: string;

  role: Observable<string>;

  description$: BehaviorSubject<string>;

  constructor(
    private el: ElementRef,
    private render: Renderer2,
    private layoutConfigService: LayoutConfigService,
    public authNoticeService: AuthNoticeService,
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private query: UserQuery) {
  }

  ngOnInit(): void {
    this.translationService.setLanguage(this.translationService.getSelectedLanguage());
    this.headerLogo = this.layoutConfigService.getLogo();

    this.splashScreenService.hide();

    this.role = this.query.data$;

    this.description$ = this.authNoticeService.description$;
  }

  /**
   * Load CSS for this specific page only, and destroy when navigate away
   * @param styleUrl
   */
  private loadCSS(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.type = 'text/css';
      styleElement.rel = 'stylesheet';
      styleElement.onload = resolve;
      this.render.appendChild(this.el.nativeElement, styleElement);
    });
  }
}
