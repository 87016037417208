import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'discount',
  pure: true
})
export class DiscountPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (args[0] === 0) {
      return false;
    }

    const normalPrice = Number(args[1]) * (args[0] + 1);

    return {
      status: value < normalPrice,
      value: ((normalPrice - value) / normalPrice * 100).toFixed(0)
    }
  }
}
