import Swal, {SweetAlertIcon} from 'sweetalert2';

export class SwalProvider {
  private static swalMixin = Swal.mixin({
    title: '',
    text: '',
    confirmButtonColor: '#279ADB',
    confirmButtonText: 'Tamam',
  });

  public static fire(title: string, text: string, icon: SweetAlertIcon) {
    return this.swalMixin.fire(title, text, icon);
  }

  public static fireError() {
    return this.swalMixin.fire('Hata', 'Bilinmeyen bir hata oluştu.', 'error');
  }
}
