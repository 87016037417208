import { MenusService } from './../../../../../../../core/services/menus.service';
import { Menu } from './../../../../../../../core/models/menu.model';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UserPackage } from '@core/models/user_packages.model';

@Component({
  selector: '[kt-client-assign-modal-item]',
  templateUrl: './client-assign-modal-item.component.html',
  styleUrls: ['./client-assign-modal-item.component.scss']
})
export class ClientAssignModalItemComponent  {
  @Input() menu: Menu;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() client: UserPackage;
  @Output() assigned = new EventEmitter<boolean>();
  date: Date;
  constructor(private menusService: MenusService) { }
  assign() {
    return this.menusService.AssingMenuToClient(this.menu.id, this.client, this.date.setHours(0, 0, 0, 0)).then(() => {
    this.assigned.emit(true);
    }).catch(() => {
      this.assigned.emit(false);
    });
  }
}
