// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   isMockEnabled: true, // You have to switch this, when your real back-end is done
//   authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
//   ipURL: 'https://www.askipo.com/api/ip',
//   cloudFunctionUrl: 'https://us-central1-askhippo-development.cloudfunctions.net/',
// };

// export const firebaseConfig = {
//   apiKey: 'AIzaSyAptyqUzFdzuhsO7WQWXUQfbqQDr2Xm50o',
//   authDomain: 'askhippo-development.firebaseapp.com',
//   databaseURL: 'https://askhippo-development.firebaseio.com',
//   projectId: 'askhippo-development',
//   storageBucket: 'askhippo-development.appspot.com',
//   messagingSenderId: '914822170849',
//   appId: '1:914822170849:web:698dbf7804636986da8fa0'
// };

// export const apiURL = 'https://us-central1-askhippo-development.cloudfunctions.net/';

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
export const environment = {
  production: true,
  apiURL: 'https://us-central1-askipo-production.cloudfunctions.net/',
  sqlApiUrl: 'https://maradona.askipo.com/api.stage/',
  agoraAppId: '2149a3ad83fd43c1ae5fdbc00a64884f',
  ipURL: 'https://www.askipo.com/api/ip',
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  cloudFunctionUrl: 'https://us-central1-askipo-production.cloudfunctions.net/',
};

export const firebaseConfig = {
  apiKey: 'AIzaSyBvwa8G2iTpLyzBsusdwbFemJ28fyswfko',
  authDomain: 'askipo-production.firebaseapp.com',
  databaseURL: 'https://askipo-production.firebaseio.com',
  projectId: 'askipo-production',
  storageBucket: 'askipo-production.appspot.com',
  messagingSenderId: '1061987882152',
  appId: '1:1061987882152:web:ac357395c5f1f243921e9d',
  measurementId: 'G-9CNZ0SE0G8'
}


export const apiURL = 'https://us-central1-askipo-production.cloudfunctions.net/';
