// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Manager } from '@core/models/manager.model';

@Injectable({
  providedIn: 'root'
})
export class MenuAsideService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  menu = [
    {
      title: 'Mevcut Satışlar',
      root: true,
      page: '/order-list',
      bullet: 'dot',
      data: { field: 'sales', type: 'write' },
    },
    {
      title: 'Üye Ekleme',
      root: true,
      page: '/member',
      bullet: 'dot',
      data: { field: 'member', type: 'write' },
    },
    {
      title: 'Ürünler',
      root: true,
      bullet: 'dot',
      submenu: [
        {
          title: 'Listele',
          page: '/partner-products/list'
        },
        {
          title: 'Yeni Oluştur',
          page: '/partner-products/edit'
        }
      ],
      data: { field: 'products', type: 'write' },
    },
    {
      title: 'Çıkış Yap',
      root: true,
      page: '/auth/login'
    },
  ];

  constructor(private fireStore: AngularFirestore) {
  }

  loadMenu(user: Manager) {
    const menu = this.menu.filter((menu) => {
      if (menu.data && !user.permissions[menu.data.field]) return false;
      return menu.data ? user.permissions[menu.data.field][menu.data.type] : true;
    });
    console.log("Menu data: ", menu);
    this.menuList$.next(menu);
    console.log(this.menuList$.value);
  }
}
