import { MeetingsModule } from './content/shared/meetings/meetings.module';
import { ChatModule } from './content/shared/chat/chat.module';
// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '@partials/partials.module';
// Pages
import { CoreModule } from '@core/core.module';
import {ProfileCardModule} from '@partials/content/dashboard/doctor/profile/profile-card.module';
import {ProfileVerticalCardModule} from '@pages/content/shared/profile-vertical-card/profile-vertical-card.module';
import {RouterModule} from '@angular/router';
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        CoreModule,
        PartialsModule,
        MeetingsModule,
        ChatModule,
        ProfileCardModule,
        ProfileVerticalCardModule,
        RouterModule,
        NgbPaginationModule,
    ],
})
export class PagesModule {
}
