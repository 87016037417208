import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProfileVerticalCardComponent} from '@pages/content/shared/profile-vertical-card/profile-vertical-card.component';

@NgModule({
  declarations: [ProfileVerticalCardComponent],
  exports: [ProfileVerticalCardComponent],
  imports: [
    CommonModule
  ]
})
export class ProfileVerticalCardModule { }
