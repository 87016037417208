import {AuthService} from './../../../../core/auth/_services/auth.service';
// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Auth
import {Subject} from 'rxjs';

import {UserStore} from '@core/store/user.store';
import firebase from 'firebase/app';
import moment from 'moment';
import Swal from 'sweetalert2';
import {AuthNoticeService} from '@core/auth';

@Component({
  selector: 'kt-register',
  templateUrl: './register.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  loading = false;
  errors: any = [];

  fullPhone: string;

  // errors
  emailInUse = false;
  ageNotValid = false;
  notAgreed = false;

  role: string;

  licenceHTML: string;
  kvkkHTML: string;
  licenceTick;
  kvkkTick;

  private unsubscribe: Subject<any>;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private store: UserStore,
    private authNoticeService: AuthNoticeService
  ) {
    this.unsubscribe = new Subject();
  }

  async ngOnInit() {
    this.role = this.store.getValue().data;

    if (!this.role) {
      this.router.navigate(['/auth/role']);
    }
    this.initRegisterForm();
    this.fullPhone = this.route.snapshot.paramMap.get('phone');

    if (this.fullPhone == null) {
      this.router.navigate(['/auth/login']);
      return;
    }

    const contracts = await this.auth.getContracts();
    this.licenceHTML = contracts.data().user;
    this.kvkkHTML = contracts.data().kvk;
    this.authNoticeService.setDescription(`Askipo'yla dilediğin yerden diyetisyenine ulaşabilir ve menünü yönetebilirsin.`);
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  showKvkk() {
    Swal.fire({
      html: '<div class="user-licence">' + this.kvkkHTML + '</div>',
      showConfirmButton: false
    });
  }

  showLicence() {
    Swal.fire({
      html: '<div class="user-licence">' + this.licenceHTML + '</div>',
      showConfirmButton: false
    });
  }

  initRegisterForm() {
    if (this.role === 'patient') {
      this.registerForm = this.fb.group({
        display_name: [
          '',
          Validators.compose([
            Validators.required,
          ])
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email
          ])
        ],
        birth_date: [
          '',
          Validators.compose([
            Validators.required,
          ])
        ]
      });
    } else {
      this.registerForm = this.fb.group({
        firstname: [
          '',
          Validators.compose([
            Validators.required,
          ])
        ],
        lastname: [
          '',
          Validators.compose([
            Validators.required,
          ])
        ],
      });
    }
  }

  async submit() {
    this.emailInUse = false;
    this.ageNotValid = false;

    if (!this.kvkkTick || !this.licenceTick) {
      this.notAgreed = true;
      return;
    }
    this.notAgreed = false;

    const controls = this.registerForm.controls;


    const phone = '+9' + this.fullPhone
      .replace('(', '')
      .replace(')', '')
      .replace(/\s/g, '');

    // check form
    if (this.registerForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      console.log('not valid');
      return;
    }

    this.loading = true;

    if (this.role === 'patient') {
      const now = firebase.firestore.Timestamp.now().toMillis();
      const age = moment(now).diff(controls.birth_date.value, 'years');

      if (age < 18) {
        this.ageNotValid = true;
        this.loading = false;
        this.cd.markForCheck();
        return;
      }

      const emailControl = await this.auth.emailControl(controls.email.value);

      if (emailControl.docs.length > 0) {
        this.loading = false;
        this.emailInUse = true;
        this.cd.markForCheck();
        return;
      }
      await this.auth.registerPatient(
        phone,
        controls.display_name.value,
        controls.email.value,
        +controls.birth_date.value,
      );
    } else {
      await this.auth.registerDoctor(
        phone,
        controls.firstname.value,
        controls.lastname.value,
      );
    }

    this.router.navigate(['/auth/confirm/' + this.fullPhone]);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
