import { ProfileCardComponent } from './profile-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';
import {BrMaskerModule} from 'br-mask';

@NgModule({
  declarations: [
    ProfileCardComponent
  ],
  imports: [
    CommonModule,
    NgbRatingModule,
    BrMaskerModule
  ],
  exports: [ProfileCardComponent]
})
export class ProfileCardModule { }
