export class MenuConfig {
  public defaults: any = {
    aside: {
      self: {},
      menu: [
        {
          title: 'Diyetisyenler',
          root: true,
          icon: 'fas fa-user-md',
          page: '/order-list',
          bullet: 'dot',
        },
        // {
        //   title: 'Yorumlarım',
        //   root: true,
        //   icon: 'fas fa-comments',
        //   page: '/mycomments',
        //   bullet: 'dot',
        // },
        {
          title: 'Çıkış Yap',
          root: true,
          icon: 'fas fa-sign-out-alt',
          page: '/auth/role'
        },
      ]
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
